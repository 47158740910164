//main: main.scss
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../fonts/lato-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v11-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
html,
body {
  height: 100%;
  margin: 0
}
.material-icons {
  font: {
    size: 1rem;
  }
  line-height: 1.4;
}
