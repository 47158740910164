//main: main.scss
#map {
  height: 50vh;
  @include media-breakpoint-up(sm) {
    height: calc(100vh - 152px);
  }
  padding: {
    left: 0;
  }
  .card {
    margin: {
      bottom: 1.375rem;
    }
    border: {
      color: $white;
    }
    min-width: 300px;
    &-title {
      font: {
        size: 1rem;
        weight: bold;
      }
      margin: {
        bottom: 0rem;
      }
      width: calc(100% - 60px);
    }
    &-text {
      font: {
        size: .75rem;
      }
    }
    a {
      font: {
        weight: bold;
      }
    }
  }
  .edit-link {
    color: $edit-color;
    &:hover {
      color: darken($edit-color, 20);
    }
  }
}

.card {
  &-market {
    background: {
      repeat: no-repeat;
      position: calc(100% - 15px) 15px;
    }
    address {
      img {
        margin: {
          top: 1rem;
        }
      }
    }
  }
  &-aldi {
    background: {
      image: url('../img/logos/logo-aldi.png');
    }
  }
  &-rewe {
    background: {
      image: url('../img/logos/logo-rewe.png');
    }
  }
  &-netto {
    background: {
      image: url('../img/logos/logo-netto.png');
    }
  }
  &-lidl {
    background: {
      image: url('../img/logos/logo-lidl.png');
    }
  }
  &-penny {
    background: {
      image: url('../img/logos/logo-penny.png');
    }
  }
  &-rossmann {
    background: {
      image: url('../img/logos/logo-rossmann.png');
    }
  }
  &-dm {
    background: {
      image: url('../img/logos/logo-dm.png');
    }
  }
  &-edeka {
    background: {
      image: url('../img/logos/logo-edeka.png');
    }
  }
  &-grundstueck {
    background: {
      image: url('../img/logos/logo-grundstueck.png');
    }
  }
}
