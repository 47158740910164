//main: main.scss
.market_nav {
  background: $market_nav;
  @include media-breakpoint-up(sm) {
    height: 72px;
  }
  display: flex;
  align-items: center;
  color: $market_nav_text;
  .custom-control-indicator {
    border: 1px solid $market_nav_text;
  }
  label {
    margin: {
      bottom: 0;
    }
  }
  div {
    flex-grow: 1;
  }
  &-text {
    margin: {
      bottom: 0.625rem;
    }
    font:{
      size: 0.625rem;
    }
    align-self: flex-end;
  }
}
