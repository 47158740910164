//main:main.scss
#sidebar {
  padding: {
    top: 35px;
    right: 30px;
    left: 30px;
  }
  h2 {
    font: {
      size: 1.375rem;
      weight: bold;
    }
  }
  section {
    height: calc(100vh - 292px);
    overflow-y: scroll;
  }
  .form-control {
    border:  {
      top: 0px;
      right: 0px;
      bottom: 1px solid $input-border-bottom-color;
      left: 0px;
    }
    padding: {
      right: 0px;
      left: 0px;
    }
    margin: {
      bottom: 22px;
    }
  }
  .card {
    margin: {
      bottom: 1.375rem;
    }
    &-title {
      font: {
        size: 1rem;
        weight: bold;
      }
      margin: {
        bottom: 0rem;
      }
      width: calc(100% - 60px);
    }
    &-text {
      font: {
        size: .75rem;
      }
    }
    a {
      font: {
        weight: bold;
      }
    }
  }
  .edit-link {
    color: $edit-color;
    &:hover {
      color: darken($edit-color, 20);
    }
  }
}
