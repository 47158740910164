//main: main.scss
//fa-var-font-awesome
$fa-font-path:        "../fonts" !default;

//overwrite bootstrap
$brand-primary: #6dbf00!default;
$enable-rounded: false;
//$enable-transitions: true;
$font-family-sans-serif: "Lato", sans-serif;
$input-border-focus: $brand-primary;
$modal-content-border-color: $brand-primary;
$modal-content-border-width: 2px;
$custom-file-text: (
  placeholder: (
    de: "Dateien auswählen..."
  ),
  button-label: (
    de: "Öffnen"
  )
) !default;
//colors
$green: #6dbf00;
$white: #fff;
$body-color: #333;

//checkbox
$custom-control-indicator-bg: $green;
$custom-control-checked-indicator-bg: lighten($custom-control-indicator-bg, 10%);
$custom-control-focus-indicator-box-shadow: none;
$custom-control-active-indicator-bg: lighten($custom-control-indicator-bg, 10%);


//headline
$header-color: $green;

//nav
$market_nav: $green;
$market_nav_text: $white;
$add-market-color: lighten($body-color, 34.50);

//sidebar
$input-border-bottom-color: lighten($body-color, 49.0196);
$card-spacer-y: 1.375rem;
$edit-color: lighten($body-color, 57.2549);
