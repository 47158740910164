//main:main.scss

header {
  height: 80px;
  display: flex!important;
  justify-content: flex-end;
  align-items: center;
  h1 {
    color: $header-color;
    font-size: 1.7rem;
    margin: {
      bottom: 0;
    }
  }
  a:first-of-type {
    flex-grow: 1;
  }
  .add_market {
    color: $add-market-color;
    flex-grow: 1;
    &:hover {
      color: darken($add-market-color, 20);
    }
  }
}
